/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

// import Header from "./header"
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css/bundle";
import "../css/theme.css";
import "../css/app.css";
import "../css/responsive.css";
import "../css/svg.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="019294fd-abba-75b9-946b-657bca6a06ab"
      ></script>
      <script type="text/javascript"></script>
      <div className="container px-xl-0">{children}</div>
      <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        Cookie Settings
      </button>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
